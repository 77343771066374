import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Snackbar, Paper, Box } from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Line } from 'react-chartjs-2';
import TaskList from './TaskList';
import ReviewCard from './ReviewCard';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import { startOfDay, addMinutes } from 'date-fns';
import { List, ListItem, ListItemText, ListItemSecondaryAction, Checkbox, IconButton } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
const API_BASE_URL = process.env.REACT_APP_API_URL;

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2), // 减少两部分之间的距离
}));

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const ChartContainer = styled('div')({
  height: '133px', // 将高度调整为原来的2/3（假设原来是200px）
});

const ProjectSteps = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
}));

const StepButton = styled(Button)(({ theme, active }) => ({
  flexGrow: 1,
  borderRadius: 0,
  borderBottom: active ? `2px solid ${theme.palette.primary.main}` : 'none',
}));

const AddTaskButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const MainContent = () => {
  const [open, setOpen] = useState(false);
  const [taskName, setTaskName] = useState('');
  const [description, setDescription] = useState('');
  const [project, setProject] = useState('');
  const [estimatedStartTime, setEstimatedStartTime] = useState(startOfDay(new Date()));
  const [estimatedEndTime, setEstimatedEndTime] = useState(addMinutes(startOfDay(new Date()), 30));
  const [actualStartTime, setActualStartTime] = useState(null);
  const [actualEndTime, setActualEndTime] = useState(null);
  const [completionRate, setCompletionRate] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [emotionData, setEmotionData] = useState([]);
  const [tasks, setTasks] = useState([]);

  const fetchTasks = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/tasks`);
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      showSnackbar('获取任务列表失败');
    }
  };

  useEffect(() => {
    // 模拟从后端获取情绪数据
    setEmotionData([3.5, 4.9, 2.0, 0.1, 3.6, 0.5]);
    fetchTasks();
  }, []);

  const emotionChartData = {
    labels: ['7:23', '10:23', '13:23', '15:23', '17:27', '19:27'],
    datasets: [{
      label: 'Mind',
      data: emotionData,
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    }]
  };

  const emotionChartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        max: 5,
        title: {
          display: true,
          text: '0: 很消极, 5: 很积极'
        }
      }
    },
    maintainAspectRatio: false,
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSubmit = async () => {
    if (!taskName || !estimatedStartTime || !estimatedEndTime) {
      showSnackbar('请填写必填字段');
      return;
    }

    if (estimatedStartTime >= estimatedEndTime) {
      showSnackbar('预计开始时间必须早于预计结束时间');
      return;
    }

    try {
      const response = await axios.post(`${API_BASE_URL}/tasks`, {
        taskName,
        description,
        project,
        estimatedStartTime,
        estimatedEndTime,
        actualStartTime,
        actualEndTime,
        completionRate
      });
      console.log('Task created:', response.data);
      handleClose();
      // 重置表单
      setTaskName('');
      setDescription('');
      setProject('');
      setEstimatedStartTime(startOfDay(new Date()));
      setEstimatedEndTime(addMinutes(startOfDay(new Date()), 30));
      setActualStartTime(null);
      setActualEndTime(null);
      setCompletionRate(0);
      showSnackbar('任务创建成功');
      
      // 重新获取任务列表
      fetchTasks();
    } catch (error) {
      console.error('Error creating task:', error);
      showSnackbar(error.response ? error.response.data.message : '创建任务时出错');
    }
  };


  const handleReviewComplete = (reviewData) => {
      console.log('Review completed and stored:', reviewData);
      // 这里可以添加将数据发送到服务器的逻辑
    };

  const renderProjectContent = () => {
    switch (activeStep) {
      case 0: // 计划
        return (
          <>
            <Typography variant="h6" gutterBottom>今天</Typography>
            <AddTaskButton
              variant="outlined"
              color="primary"
              fullWidth
              onClick={handleOpen}
            >
              + 添加任务至 "今天任务列表"
            </AddTaskButton>
            <TaskList />
          </>
        );
      case 1: // 执行
        return <Typography>执行情况展示区域</Typography>;
      case 2: // 检查
        return <Typography>检查情况展示区域</Typography>;
      case 3: // 复盘
        return <Typography>
                  <ReviewCard onReviewComplete={handleReviewComplete} />
        </Typography>;
      default:
        return null;
    }
  };

  const TaskList = () => (
    <List>
      {tasks.map((task) => (
        <ListItem key={task._id}>
          <Checkbox
            checked={task.completionRate === 100}
            onChange={() => {/* Handle checkbox change */}}
          />
          <ListItemText 
            primary={task.taskName}
            secondary={`预计开始: ${new Date(task.estimatedStartTime).toLocaleString()}`}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="edit">
              <EditIcon />
            </IconButton>
            <IconButton edge="end" aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );

  return (
    <Root>
      <Section>
        <Typography variant="h5" gutterBottom>目标管理</Typography>
        <ProjectSteps>
          {['计划', '执行', '检查', '复盘'].map((step, index) => (
            <StepButton
              key={step}
              onClick={() => setActiveStep(index)}
              active={activeStep === index}
              variant={activeStep === index ? "contained" : "outlined"}
            >
              {step}
            </StepButton>
          ))}
        </ProjectSteps>
        {renderProjectContent()}
      </Section>



      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>新建任务</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="任务名称"
            fullWidth
            required
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="描述"
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            margin="dense"
            label="所属项目"
            fullWidth
            value={project}
            onChange={(e) => setProject(e.target.value)}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="预计开始时间"
              value={estimatedStartTime}
              onChange={setEstimatedStartTime}
              renderInput={(params) => <TextField {...params} fullWidth margin="dense" required />}
            />
            <DateTimePicker
              label="预计结束时间"
              value={estimatedEndTime}
              onChange={setEstimatedEndTime}
              renderInput={(params) => <TextField {...params} fullWidth margin="dense" required />}
            />
            <DateTimePicker
              label="实际开始时间"
              value={actualStartTime}
              onChange={setActualStartTime}
              renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
            />
            <DateTimePicker
              label="实际结束时间"
              value={actualEndTime}
              onChange={setActualEndTime}
              renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
            />
          </LocalizationProvider>
          <TextField
            margin="dense"
            label="完成度"
            type="number"
            fullWidth
            value={completionRate}
            onChange={(e) => setCompletionRate(Number(e.target.value))}
            inputProps={{ min: 0, max: 100 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
          <Button onClick={handleSubmit}>提交</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Root>
  );
};

export default MainContent;
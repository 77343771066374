import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
  },
  margin: theme.spacing(2),
  padding: theme.spacing(2),
}));

const SectionCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
}));

// 模拟数据保持不变
const mockReviewData = {
  // ... 保持原有的模拟数据不变
  emotionTrend: [
    { time: '09:00', value: 7 },
    { time: '11:00', value: 6 },
    { time: '13:00', value: 8 },
    { time: '15:00', value: 5 },
    { time: '17:00', value: 9 },
  ],
  completedTasks: [
    { id: 1, name: '完成项目报告', duration: '2小时' },
    { id: 2, name: '团队会议', duration: '1小时' },
  ],
  incompleteTasks: [
    { id: 3, name: '回复邮件', estimatedDuration: '30分钟' },
    { id: 4, name: '代码审查', estimatedDuration: '1小时' },
  ],
  goodPractices: '今天在专注工作时使用了番茄工作法，效果很好。',
  gapAnalysis: '计划完成4个任务，实际只完成了2个。时间管理还需要改进。',
  possibleReasons: '可能是因为上午的会议拖延，影响了下午的工作计划。',
  improvementSuggestions: '建议明天尝试更细致的时间规划，将任务分解为更小的步骤。',
  cognitiveBiases: '发现有完成度偏差，倾向于高估自己的工作效率。建议更客观地评估任务难度和所需时间。',
  emotionalAnalysis: '下午3点左右情绪出现低谷，可能与工作压力有关。建议在这个时间段安排短暂休息或进行一些轻松的任务。',
};

const EmotionChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={200}>
    <LineChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="time" />
      <YAxis />
      <Tooltip />
      <Line type="monotone" dataKey="value" stroke="#8884d8" />
    </LineChart>
  </ResponsiveContainer>
);

const TaskList = ({ tasks, title }) => (
  <SectionCard>
    <CardContent>
      <SectionTitle variant="h6">{title}</SectionTitle>
      {tasks.map((task) => (
        <Typography key={task.id} variant="body2">
          • {task.name} ({task.duration || task.estimatedDuration})
        </Typography>
      ))}
    </CardContent>
  </SectionCard>
);

const InfoCard = ({ title, content }) => (
  <SectionCard>
    <CardContent>
      <SectionTitle variant="h6">{title}</SectionTitle>
      <Typography variant="body2">{content}</Typography>
    </CardContent>
  </SectionCard>
);

const ReviewCard = ({ onReviewComplete }) => {
  const [reviewData, setReviewData] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setReviewData(mockReviewData);
      setIsDialogOpen(true);
    }, 1000);
  }, []);

  const handleStoreReview = () => {
    setIsDialogOpen(false);
    onReviewComplete(reviewData);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  if (!reviewData) {
    return <Typography>正在进行复盘分析...</Typography>;
  }

  return (
    <StyledCard>
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          今日复盘
        </Typography>
        
        <SectionTitle variant="h6">情绪趋势</SectionTitle>
        <EmotionChart data={reviewData.emotionTrend} />
        
        <Grid container spacing={2} style={{ marginTop: '16px' }}>
          <Grid item xs={12} sm={6}>
            <TaskList tasks={reviewData.completedTasks} title="已完成任务" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TaskList tasks={reviewData.incompleteTasks} title="未完成任务" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoCard title="良好实践" content={reviewData.goodPractices} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoCard title="差距分析" content={reviewData.gapAnalysis} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoCard title="可能原因" content={reviewData.possibleReasons} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoCard title="改进建议" content={reviewData.improvementSuggestions} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoCard title="认知偏差分析" content={reviewData.cognitiveBiases} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InfoCard title="情绪分析" content={reviewData.emotionalAnalysis} />
          </Grid>
        </Grid>
      </CardContent>
      
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>确认存储复盘内容</DialogTitle>
        <DialogContent>
          <Typography>是否存储这次复盘内容？</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>取消</Button>
          <Button onClick={handleStoreReview} color="primary">
            存储
          </Button>
        </DialogActions>
      </Dialog>
    </StyledCard>
  );
};

export default ReviewCard;
import React, { useState, useEffect, useRef } from 'react';
import { TextField, IconButton, List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, CircularProgress } from '@mui/material';
import { Send as SendIcon, Mic as MicIcon, Stop as StopIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import axios from 'axios';

const RASA_BASE_URL = process.env.REACT_APP_RASA_BASE_URL || '/api';

// Styled components (unchanged)
const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const Header = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
}));

const MessageList = styled(List)({
  flexGrow: 1,
  overflow: 'auto',
  padding: '16px',
});

const InputContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '10px',
  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
});

const StyledTextField = styled(TextField)({
  flexGrow: 1,
  marginRight: '10px',
});

const VoiceInputContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginRight: '10px',
});

// Typing effect component
const TypingEffect = ({ text }) => {
  const [displayText, setDisplayText] = useState('');
  const index = useRef(0);

  useEffect(() => {
    if (index.current < text.length) {
      const timeoutId = setTimeout(() => {
        setDisplayText((prev) => prev + text[index.current]);
        index.current += 1;
      }, 50);
      return () => clearTimeout(timeoutId);
    }
  }, [displayText, text]);

  return <span>{displayText}</span>;
};

const ChatWindow = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [transcript, setTranscript] = useState('');
  const recognition = useRef(null);
  const messageQueue = useRef([]);
  const messagesEndRef = useRef(null);

  const [isSpeechRecognitionSupported, setIsSpeechRecognitionSupported] = useState(false);

  useEffect(() => {
    // 检查浏览器是否支持语音识别
    if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
      const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
      recognition.current = new SpeechRecognition();
      recognition.current.continuous = true;
      recognition.current.interimResults = true;
      recognition.current.lang = 'zh-CN'; // 设置为中文

      recognition.current.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map(result => result[0].transcript)
          .join('');
        setTranscript(transcript);
      };

      recognition.current.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setIsRecording(false);
      };

      setIsSpeechRecognitionSupported(true);
    } else {
      console.warn('Speech recognition is not supported in this browser.');
      setIsSpeechRecognitionSupported(false);
    }
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const sendMessage = async (message) => {
    const tempId = Date.now();
    const currentTime = new Date().toISOString();
    setMessages(prev => [...prev, { id: tempId, text: message, sender: 'user', time: currentTime }]);
    setIsLoading(true);
    
    try {
      console.log("Sending message:", message);
      const response = await axios.post(`${RASA_BASE_URL}/rasa/chat`, { 
        message: encodeURIComponent(message)
      }, {
        headers: { 'Content-Type': 'application/json; charset=UTF-8' }
      });

      console.log("Raw response:", response.data);

      let aiResponse = '';
      if (typeof response.data === 'object' && response.data.response) {
        aiResponse = response.data.response;
      } else if (typeof response.data === 'string') {
        try {
          const parsedData = JSON.parse(response.data);
          aiResponse = parsedData.response || parsedData.text || "I couldn't understand the response.";
        } catch (parseError) {
          console.error("Error parsing response:", parseError);
          aiResponse = response.data;
        }
      } else {
        aiResponse = "I couldn't understand the response.";
      }

      console.log("Processed AI Response:", aiResponse);

      setMessages(prev => [
        ...prev.filter(msg => msg.id !== tempId),
        { id: tempId, text: message, sender: 'user', time: currentTime },
        { id: Date.now(), text: aiResponse, sender: 'AI', time: new Date().toISOString() }
      ]);
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prev => [
        ...prev.filter(msg => msg.id !== tempId),
        { id: tempId, text: message, sender: 'user', time: currentTime },
        { id: Date.now(), text: 'Sorry, there was an error processing your message.', sender: 'AI', time: new Date().toISOString() }
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const processQueue = async () => {
    while (messageQueue.current.length > 0) {
      const message = messageQueue.current.shift();
      await sendMessage(message);
    }
  };

  const handleSend = () => {
    if (input.trim()) {
      messageQueue.current.push(input);
      setInput('');
      if (messageQueue.current.length === 1) {
        processQueue();
      }
    }
  };

  // Handle key press (Enter to send)
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

const startRecording = () => {
    if (recognition.current) {
      setIsRecording(true);
      setTranscript('');
      recognition.current.start();
    } else {
      console.error('Speech recognition is not initialized');
    }
  };

  const stopRecording = () => {
    if (recognition.current) {
      setIsRecording(false);
      recognition.current.stop();
      setInput(transcript);
    }
  };

  return (
    <Root>
      <Header>
        <Typography variant="h6">AI 教练</Typography>
      </Header>
      <MessageList>
        {messages.map((message) => (
          <ListItem key={message.id} alignItems="flex-start">
            <ListItemAvatar>
              <Avatar>{message.sender === 'user' ? 'U' : 'AI'}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={message.text}
              secondary={new Date(message.time).toLocaleTimeString()}
            />
          </ListItem>
        ))}
        {isLoading && (
          <ListItem>
            <CircularProgress size={20} />
            <Typography variant="body2" style={{ marginLeft: 10 }}>
              AI is thinking...
            </Typography>
          </ListItem>
        )}
        <div ref={messagesEndRef} />
      </MessageList>
      <InputContainer>
        <VoiceInputContainer>
          {isSpeechRecognitionSupported && (
            <IconButton 
              onClick={isRecording ? stopRecording : startRecording} 
              disabled={isLoading}
              title={isRecording ? "停止录音" : "开始录音"}
            >
              {isRecording ? <StopIcon color="secondary" /> : <MicIcon />}
            </IconButton>
          )}
          {isRecording && <CircularProgress size={24} />}
        </VoiceInputContainer>
        <StyledTextField
          value={isRecording ? transcript : input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && !isLoading && handleSend()}
          placeholder="输入你的消息..."
          variant="outlined"
          size="small"
          disabled={isRecording || isLoading}
        />
        <IconButton 
          onClick={handleSend} 
          color="primary" 
          disabled={isRecording || isLoading || !input.trim()}
          title="发送消息"
        >
          <SendIcon />
        </IconButton>
      </InputContainer>
    </Root>
  );
};

export default ChatWindow;
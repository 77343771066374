import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { TextField, List, ListItem, ListItemText, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const SearchInput = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const DateList = styled(List)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const Sidebar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const dateOptions = [
    { label: '今天', value: new Date() },
    { label: '昨天', value: new Date(Date.now() - 86400000) },
    { label: '2024 0623', value: new Date('2024-06-23') },
    { label: '2024 0622', value: new Date('2024-06-22') },
    { label: '2024 0621', value: new Date('2024-06-21') },
  ];

  return (
    <Root>
      <Title variant="h6">onething</Title>
      <SearchInput
        fullWidth
        placeholder="input key word"
        variant="outlined"
        size="small"
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={selectedDate}
          onChange={setSelectedDate}
          renderInput={(params) => <TextField {...params}/>}
        />
      </LocalizationProvider>
      <DateList>
        {dateOptions.map((option) => (
          <ListItem button key={option.label} selected={option.label === '今天'}>
            <ListItemText primary={option.label} />
          </ListItem>
        ))}
      </DateList>
    </Root>
  );
};

export default Sidebar;
import React from 'react';
import { styled } from '@mui/material/styles';
import Sidebar from './Sidebar';
import MainContent from './MainContent';
import ChatWindow from './ChatWindow';

const LayoutContainer = styled('div')({
  display: 'flex',
  height: '100vh',
});

const SidebarWrapper = styled('div')({
  width: '250px',
  flexShrink: 0,
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
});

const ContentChatWrapper = styled('div')({
  flex: 1,
  display: 'flex',
});

const MainContentWrapper = styled('div')({
  flex: 1,
  overflow: 'auto',
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
});

const ChatWindowWrapper = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const Layout = () => {
  return (
    <LayoutContainer>
      <SidebarWrapper>
        <Sidebar />
      </SidebarWrapper>
      <ContentChatWrapper>
        <MainContentWrapper>
          <MainContent />
        </MainContentWrapper>
        <ChatWindowWrapper>
          <ChatWindow />
        </ChatWindowWrapper>
      </ContentChatWrapper>
    </LayoutContainer>
  );
};

export default Layout;
import React from 'react';
import { styled } from '@mui/material/styles';
import { List, ListItem, ListItemText, ListItemSecondaryAction, Checkbox, IconButton } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const TaskList = () => {
  const tasks = [
    { id: 1, title: '心理图谱设计', completed: true },
    { id: 2, title: '用户调研', completed: false },
    { id: 3, title: '原型设计', completed: false },
  ];

  return (
    <List>
      {tasks.map((task) => (
        <StyledListItem key={task.id}>
          <Checkbox
            checked={task.completed}
            onChange={() => {/* Handle checkbox change */}}
          />
          <ListItemText primary={task.title} />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="edit">
              <EditIcon />
            </IconButton>
            <IconButton edge="end" aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </StyledListItem>
      ))}
    </List>
  );
};

export default TaskList;